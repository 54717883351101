import { LoginConstant } from "../constants/auth.constant";
const CryptoJS = require("crypto-js");
const SecureStorage = require('secure-web-storage')
const SECRET_KEY = 'secureMyData';

export var secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key: any) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data: any) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data: any) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }
});

const initialState = {
  user: secureStorage.getItem('user') ? secureStorage.getItem('user') : null,
  isUserLoggedIn: false,
  msg: "",
  isLoading: false,
  isAdmin: secureStorage.getItem('user') ? secureStorage.getItem('user').isAdmin : false,
  token: secureStorage.getItem('token') ? secureStorage.getItem('token') : null
};
console.log("insitalstate", initialState)
export type AuthInitialStateType = {
  user: object;
  isUserLoggedIn: boolean;
  msg: string;
  isLoading: boolean;
  token: string;
  isAdmin: boolean;
};
export default function reducer(state = initialState, action: { type: any; user: any; error: any }): AuthInitialStateType {
  switch (action.type) {
    case LoginConstant.REQUEST_LOGIN:
      let userObject = action && action.user ? action.user : {};
      if (userObject.rememberMe) {
        localStorage.setItem(btoa("user"), btoa(JSON.stringify(userObject)));
      }
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case LoginConstant.SUCCESS_LOGIN:
      console.log("actions", action);
      let user = action && action.user ? action.user : {};
      secureStorage.setItem('user', user);
      secureStorage.setItem('token', user.token);
      return {
        ...state,
        user: user,
        isUserLoggedIn: true,
        isLoading: false,
        token: user.token,
        isAdmin: user.isAdmin
      };
    case LoginConstant.FAILURE_LOGIN:
      let message = action && action.error ? action.error : {};
      return {
        ...state,
        msg: message,
        isLoading: false,
      };
    case LoginConstant.LOG_OUT:
      secureStorage.clear()
      return {
        ...state,
        user: {},
        isLoading: false,
        isUserLoggedIn: false,
        token: '',
        msg: "",
      };
    case LoginConstant.CLEARM_MSG:
      return {
        ...state,
        msg: "",
      };
    default:
      return state;
  }
}
