import { combineReducers } from "redux";
import themeReducer from "./themeReducers";
import authReducer from "./authReducers";
import usersReducer from "./usersReducers";

export const rootReducer = combineReducers({
  authReducer: authReducer,
  themeReducer: themeReducer,
  usersReducer: usersReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
