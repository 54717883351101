import { UserConstant } from "../constants/user.constant";

const initialState = {
  usersList: [],
  clientList: [],
  newUser: "",
  msg: "",
  isLoading: false,
  userPermissions: [],
  nominationList: [],
  updated: false,
};

export type AuthInitialStateType = {
  usersList: object;
  newUser: string;
  msg: string;
  isLoading: boolean;
  userPermissions: any;
  clientList: any;
  nominationList: object;
  updated: boolean;
};
export default function reducer(
  state = initialState,
  action: {
    type: any;
    usersList: any;
    clientList: any;
    list: any;
    newUser: any;
    error: any;
    msg: any;
  }
): AuthInitialStateType {
  switch (action.type) {
    case UserConstant.FETCH_USER_LIST:
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case UserConstant.SUCCESS_USER_LIST:
      let usersList = action && action.usersList ? action.usersList : [];
      return {
        ...state,
        usersList: usersList,
        isLoading: false,
      };
    case UserConstant.FAILURE_USER_LIST:
      let msg = action && action.error ? action.error : {};
      return {
        ...state,
        msg: msg,
        isLoading: false,
      };
    case UserConstant.CLEAR_MSG:
      return {
        ...state,
        msg: "",
        newUser: "",
        isLoading: false,
      };
    case UserConstant.FETCH_CLIENT_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case UserConstant.SUCCESS_CLIENT_LIST:
      let cList = action && action.clientList ? action.clientList : [];
      return {
        ...state,
        clientList: cList,
        isLoading: false,
      };
    case UserConstant.FAILURE_CLIENT_LIST:
      let userMsg = action && action.error ? action.error : {};
      return {
        ...state,
        msg: userMsg,
        isLoading: false,
      };
    case UserConstant.SET_ADD_NEW_USER:
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case UserConstant.SUCCESS_ADD_NEW_USER:
      let newUser = action && action.newUser ? action.newUser : "";
      return {
        ...state,
        isLoading: false,
        msg: "success",
        newUser: newUser,
      };
    case UserConstant.FAILURE_ADD_NEW_USER:
      let error = action && action.error ? action.error : "";
      return {
        ...state,
        msg: error,
        isLoading: false,
      };
    case UserConstant.CLEAR_USER_LIST:
      return {
        ...state,
        msg: "",
        isLoading: false,
        usersList: [],
      };

    case UserConstant.FETCH_NOMINATION_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case UserConstant.SUCCESS_NOMINATION_LIST:
      let list = action && action.list ? action.list : [];
      return {
        ...state,
        nominationList: list,
        isLoading: false,
      };
    case UserConstant.FAILURE_NOMINATION_LIST:
      let err = action && action.error ? action.error : {};
      return {
        ...state,
        msg: err,
        isLoading: false,
      };

    case UserConstant.SET_USER_STATUS:
      return {
        ...state,
        updated: false,
        isLoading: true,
      };

    case UserConstant.SUCCESS_USER_STATUS:
      let successStatus = action && action.msg ? action.msg : "";
      return {
        ...state,
        updated: true,
        msg: successStatus,
        isLoading: false,
      };
    case UserConstant.FAILURE_USER_STATUS:
      let errStatus = action && action.error ? action.error : {};
      return {
        ...state,
        msg: errStatus,
        updated: false,
        isLoading: false,
      };

    default:
      return state;
  }
}
